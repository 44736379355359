@font-face {
	font-family: "TuskerGroteskBold";
	src: local("TuskerGroteskBold"),
	 url("../fonts/TuskerGroteskBold.otf") format("opentype");
	font-weight: bold;
}

.ww-fontface {
	font-family: "TuskerGroteskBold";
	font-color: "#FAF9F6";
	color: "#FAF9F6";
	letter-spacing: 0.05em;
}

.ww-navbar {
	background-color: "#000000";
	color: "#000000";
}



.background-div {
	background-color: #000000;
}
